.btn-more-less {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 300;
  color: $gray-800;
  text-decoration: none;
  &:hover {
    color: $primary;
  }
}
.section-html p {
  margin-bottom: 0.5rem;
}
.section-homepage-carousel {
  img {
    border-radius: 10px;
  }
}

// Contact page
.s-contact {
  &__inner {
    display: flex;
    color: $gray-700;
    font-size: 16px;
    font-weight: 600;
  }
  &__title {
    font-weight: 800;
    font-size: 16px;
    margin-bottom: 0.5rem;
  }
  &__icon {
    margin-top: 0.15rem;
    margin-right: 0.5rem;
    color: $primary;
    font-size: 20px;
    width: 1.25em;
    text-align: center;
  }

  &__link.custom-link {
    padding-bottom: 0;
    color: $gray-700;
    text-decoration: none;

    &:hover {
      color: $primary;
    }

    &:before {
      bottom: 0.125rem;
    }
  }
}
