.s-footer {
  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
    padding: 0;
    background-color: transparent;
    border: 0;
    font-weight: 700;
    font-size: 14px;
    color: $white;
    line-height: 1;
    text-transform: uppercase;
    width: 100%;
    .icon {
      margin-left: auto;
      margin-right: 1.5rem;
      font-size: 14px;
      transform: rotate(-180deg);
      transition: transform 0.3s;
    }
    &.collapsed .icon {
      transform: rotate(0);
    }
    @include media-breakpoint-up(md) {
      margin-bottom: 20px;
      font-size: 16px;
      .icon {
        display: none;
      }
    }
    @include media-breakpoint-up(lg) {
      font-size: 18px;
    }
  }
  .nav {
    flex-direction: column;
  }
  .nav-link {
    display: block;
    margin-bottom: 12px;
    padding: 0;
    font-weight: 500;
    font-size: 12px;
    color: #c1c1c1;
    line-height: 15px;
    text-decoration: none;
    transition: $btn-transition;
    &:hover {
      color: $primary;
    }
  }
  @include media-breakpoint-up(md) {
    &__collapse {
      display: block !important;
      height: auto !important;
    }
  }

  &__phone {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    a {
      font-weight: 600;
      font-size: 18px;
      color: $white;
      line-height: 30px;
      text-decoration: none;
      transition: $btn-transition;
      &:hover {
        color: $primary;
      }
    }
    .icon {
      flex: 0 0 1.25em;
      width: 1.25em;
      margin-right: 5px;
      font-size: 25px;
      color: $secondary;
    }
  }
  &__info {
    margin-bottom: 8px;
    display: flex;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;

    .icon {
      margin-top: 2px;
      width: 1.25em;
      text-align: left;
      margin-right: 5px;
      font-size: 15px;
      flex: 0 0 1.25em;
      color: $secondary;
    }
    a {
      text-decoration: none;
      color: $white;
      transition: $btn-transition;
      &:hover {
        color: $primary;
      }
    }
  }

  &__bottom {
    border-top: 1px solid $gray-300;
  }
  &__sn {
    margin-top: 1rem;
    margin-bottom: 1rem;
    a {
      margin: 0;
      font-size: 22px;
      color: $primary;
      text-decoration: none;
      &:hover {
        opacity: 0.75;
      }
      + a {
        margin-left: 20px;
      }
    }
    .icon,
    svg {
      display: block;
    }
  }
  &__powered {
    color: $gray-400;
    line-height: 1;
  }
}

.to-top-button {
  position: fixed;
  z-index: 99;
  right: 20px;
  bottom: -100%;
  visibility: hidden;
  opacity: 0;
  line-height: 1;
  transition: color 0.15s ease-in-out, bottom 0.15s ease-in-out,
    background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    opacity 0.15s ease-in-out, visibility 0.15s ease-in-out;
}

.to-top-button.show {
  visibility: visible;
  opacity: 1;
  bottom: 20px;
}
