// Lozad
.lozad.fade {
  opacity: 1;
  transition: opacity 0.15s ease-out;
  &:not([data-loaded="true"]) {
    opacity: 0;
  }
}

.section-products {
  margin: 0 -10px;
  overflow: hidden;
  .swiper-viewport {
    padding: 10px;
    overflow: hidden;
  }
  .swiper-container {
    overflow: visible;
  }
}

.swiper-button-prev,
.swiper-button-next {
  color: $primary;
}

.swiper-slide:not(.swiper-slide-visible) .card {
  box-shadow: none !important;
}

// Pagination
.page-link svg {
  margin-top: -3px;
}

.rounded-sm {
  border-radius: $border-radius-sm;
}

// Pagination
.pagination {
  .page-item {
    min-width: 35px;
    font-weight: 500;
    text-align: center;
  }
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
  margin-bottom: -1rem;
  .nav {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 1rem;
    margin-top: 1px;
    overflow-x: auto;
    text-align: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
  }
}

// Mobile menu
.s-mMenu {
  &__link {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    color: $gray-800;
    line-height: 1.25;
    text-decoration: none;
  }
  .navbar-nav__link {
    top: 5px;
    &:not(.collapsed) .icon {
      transform: rotate(90deg);
    }
  }
}
.s-mMenu__links {
  .nav {
    flex-direction: column;
    padding-left: 2rem;
  }
  .nav-link {
    color: $gray-700;
  }
}
