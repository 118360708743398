.s-categories {
  &__link {
    display: block;
    font-size: 15px;
    font-weight: 400;
    color: $gray-800;
    line-height: 1;
    text-decoration: none;
    &-sm {
      font-size: 13px;
    }
  }
}

.s-sort {
  &__label {
    font-weight: 500;
    font-size: 16px;
    line-height: 15px;
  }
  &__btn {
    border: 0;
    padding-top: 0.375rem;
    padding-left: 1rem;
    width: 100%;
    font-weight: 400;

    .icon {
      color: $primary;
    }
  }
  &__dropdown {
    min-width: auto;
    padding: 0;
    width: 100%;

    .btn {
      width: 100%;
      border: 0;
      padding: 8px 16px;
      font-weight: 500;
      font-size: 13px;
      text-align: left;
      &:hover,
      &.active {
        background: $gray-100;
      }
    }
  }
  &__reloadLimit &__btn {
    min-width: 70px;
  }
}

.s-products {
  .card-img-top {
    text-align: center;
  }
  &__image-alt {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 3;
    transition: opacity 0.1s linear;
    max-width: 100%;
    max-height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    display: none;
  }
  @include media-breakpoint-up(md) {
    &__image-alt {
      display: block;
    }
    .card:hover &__image-alt {
      opacity: 1;
    }
  }
  &__title {
    display: block;
    margin-top: 0.75em;
    margin-bottom: 0.5rem;
    font-weight: 600;
    font-size: 12px;
    line-height: 1;
    text-decoration: none;
    &:hover {
      color: $gray-700;
    }
    @include media-breakpoint-up(sm) {
      font-size: 14px;
      min-height: 42px;
    }
  }
  &__model {
    font-weight: 400;
    font-size: 12px;
    color: $gray-400;
  }
  &__price {
    font-weight: 700;
    font-size: 16px;
    color: $secondary;
  }
  &__old {
    font-weight: 500;
    font-size: 14px;
    color: $gray-500;
    text-decoration: line-through;
  }
  &__special,
  &__price {
    line-height: 17px;
  }
  &__noprice {
    font-weight: bold;
    line-height: 17px;
  }
  @include media-breakpoint-down(sm) {
    &__old,
    &__noprice {
      font-size: 12px;
    }
    &__special,
    &__price {
      font-size: 14px;
      white-space: nowrap;
    }
  }
  &__btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding-left: 1rem;
    padding-right: 1rem;
    @include media-breakpoint-up(sm) {
      min-width: 50%;
    }
    @include media-breakpoint-down(sm) {
      padding: 5px;
      font-size: 0;
    }
    .icon {
      margin-right: 5px;
      margin-top: -1px;

      @include media-breakpoint-down(sm) {
        font-size: 24px;
        padding-left: 5px;
      }
    }
  }
  &__icons {
    flex: 0 0 50%;
    justify-content: space-between;
  }
  &__icon {
    padding: 4px;
    font-size: 18px;
    width: 28px;
    height: 28px;
    display: block;
    text-decoration: none;
    @include media-breakpoint-up(sm) {
      font-size: 22px;
      width: 35px;
      height: 35px;
    }
  }
  @include media-breakpoint-down(sm) {
    &__icons {
      margin-right: -4px;
      position: absolute;
      top: 8px;
      right: 8px;
      flex-direction: column;
      gap: 4px;
      z-index: 5;
    }
    &__status {
      text-align: center;
    }
  }
  &__status {
    margin-top: 4px;
    font-size: 12px;
    color: $gray-700;
    line-height: 1;
  }
  &__labels {
    position: absolute;
    left: 5px;
    top: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    z-index: 5;
  }
  &__label {
    margin-bottom: 2px;
    background-color: $gray-200;
    border-radius: 5px;
    padding: 3px 10px;
    font-weight: 500;
    font-size: 12px;
    color: $gray-800;
    text-transform: uppercase;
    line-height: 1;
    @include media-breakpoint-up(sm) {
      margin-bottom: 5px;
      padding: 5px 10px;
      font-size: 13px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .s-card__rating {
    justify-content: center;
  }
}
