// html {
//   scroll-padding-top: 5.5rem;
// }

body {
  min-width: 320px;
}

.section {
  padding-bottom: 40px;
}
.block-title {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1;
}

// Container
.container {
  &,
  &-xxl {
    .container,
    .container-xxl {
      padding: 0;
    }
  }
}

@media (min-width: 1200px) {
  .row-cols-xl-7 > * {
    flex: 0 0 auto;
    width: 14.285%;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100vw;
  height: 100vh;
  background-color: $black;

  &.fade {
    opacity: 0;
  }

  &.show {
    opacity: 0.5;
  }
}

// Content row
#column-left {
  @include media-breakpoint-up(xl) {
    width: 20%;
  }

  ~ #content {
    flex: 1 0 0%;
  }
}

// Page
.s-page {
  &__title {
    margin-bottom: 0;
    font-weight: 800;
    font-size: 18px;
    line-height: 1;
  }

  &__description {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6 {
      font-weight: 800;
    }
  }

  p:last-child {
    margin-bottom: 0;
  }

  &__manufacturer a {
    margin: 4px 0;
    font-weight: 600;
    font-size: 15px;
    color: $gray-800;
    text-decoration: none;
    &:hover {
      color: $primary;
    }
  }
}

// Load more
.btn-wicon,
.btn-load-more {
  display: inline-flex;
  align-items: center;
  font-size: 14px;

  .icon {
    margin-top: -2px;
    margin-bottom: -2px;
    margin-left: 8px;
    width: 18px;
    height: 18px;
    line-height: 20px;
    text-align: center;
  }
}

.btn-load-more.animated .icon {
  -webkit-animation: 0.5s linear rotate infinite;
  animation: 0.5s linear rotate infinite;
}

@-webkit-keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

// Breadcrumbs
.breadcrumb {
  overflow: hidden;
  margin-bottom: 10px;
}
.breadcrumb-item {
  display: flex;
  align-items: center;
  font-weight: 500;
  + .breadcrumb-item::before {
    display: none;
  }
  a {
    color: $gray-800;
    text-decoration: none;
    &:hover {
      color: $gray-600;
    }
  }
  .icon {
    margin-top: -1px;
    margin-right: 0.625rem;
    font-size: 14px;
    color: $primary;
  }
  span {
    color: $gray-800;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
  }
  @include media-breakpoint-down(sm) {
    font-size: 10px;
    + .breadcrumb-item {
      padding-left: 0.375rem !important;
    }
    .icon {
      margin-right: 0.375rem;
    }
  }
}

@include media-breakpoint-up(lg) {
  .row-home #column-left {
    width: 25%;
    padding-right: 10px;
    .navbar-nav {
      max-width: 310px;
    }
  }
  .col-home-right {
    width: 75%;
  }
}

@include media-breakpoint-down(xl) {
  .row-home .column-left {
    display: none;
  }
}

.mfp-content {
  max-height: 100%;
}

// Modal
.modal-header {
  border-bottom: none;
  align-items: normal;
  padding: 24px 16px 12px;

  @include media-breakpoint-up(sm) {
    padding: 32px 40px 12px;
  }
}

.modal-title {
  width: 100%;
  font-weight: bold;
  line-height: 1.2;
}

.modal-body {
  padding: 16px;

  @include media-breakpoint-up(sm) {
    padding: 20px 40px;
  }
}

.modal-footer {
  border-top: none;
  padding: 0 16px 20px;

  @include media-breakpoint-up(sm) {
    padding: 0 40px 32px;
  }
}
@include media-breakpoint-down(md) {
  .modal-footer > * {
    margin: 0;
  }
}

// Info pages
.my-display {
  position: relative;
}
.first-top {
  position: absolute;
  z-index: 1;
  background-color: #2c2b2b80;
  color: #fff;
  padding: 15px;
  ul {
    list-style-type: none;
  }
}
.right-margin {
  margin-right: 20px;
}
#information-information {
  strong,
  b {
    font-weight: 600;
  }
}
@media only screen and (max-width: 1024px) {
  .my-display {
    display: block !important;
  }
  .first-top {
    width: 100% !important;
    position: static !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    ul {
      padding-left: 0;
    }
    li {
      margin-left: 0 !important;
    }
    [style="font-size: 15px;"] {
      font-size: 13px !important;
    }
  }
  .second-top {
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: 100% !important;
  }
}

.cart-quantity {
  min-width: 120px;
}

.card-date {
  align-items: center;
  color: #333;
  display: flex;
  font-size: 13px;
  font-weight: 500;
  line-height: 13px;
  margin-bottom: 0.5rem;
  .icon {
    color: $primary;
    font-size: 20px;
    margin-right: 0.25rem;
    width: 1.25em;
  }
}

// Articles card
.s-articles {
  &__overlay {
    display: flex;
    align-items: end;
    background-color: rgba($black, 0.4);
    overflow: hidden;
  }
  &__link {
    width: 100%;
    font-weight: 500;
    font-size: 15px;
    color: $white;
    text-decoration: none;
    line-height: 1.2;
  }
  &__title {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
    padding: 0.625rem;
    z-index: 1;
    position: relative;
    &::before {
      display: block;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      background-color: transparent;
      background-image: linear-gradient(90deg, $primary 33%, transparent 65%);
      z-index: -1;
      transition: right 0.3s;
    }
  }
  .card:hover &__title::before {
    right: -300%;
  }
  &__icon {
    margin-left: auto;
    margin-right: 1rem;
    padding-left: 1rem;
    font-size: 25px;
  }
  .card-text {
    color: #333;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
}

.s-article {
  &__description {
    font-weight: 500;
    font-size: 14px;
    color: $gray-800;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 900;
      line-height: 1;
    }

    img {
      clear: both;
      max-width: 100%;
      height: auto !important;
      display: block;
      margin: 12px auto;
    }
  }
}

.s-services {
  .card {
    height: 100%;
    justify-content: center;
  }
  .card-body {
    flex-grow: 0;
    a {
      text-decoration: none;
      color: $gray-800;
      &:hover {
        color: $primary;
      }
    }
  }
  .card-image {
    margin-bottom: 20px;
    img {
      max-width: 90px;
      height: auto !important;
    }
  }
  .card-title {
    margin-bottom: 20px;
    font-size: 15px;
    font-weight: 500;
    color: $gray-800;
    text-transform: uppercase;
    line-height: 1;
  }
  @include media-breakpoint-up(md) {
    .section-html > .row > .col-12:first-child {
      .card-image img {
        max-width: 130px;
      }
      .card-title {
        font-size: 20px;
      }
    }
  }
  .list-unstyled {
    margin-left: -12px;
    margin-right: -12px;
    line-height: 15px;
    font-weight: 400;
    li + li {
      margin-top: 10px;
    }
  }
}

.full-image img {
  width: 100%;
}
.icon-image img {
  width: 50%;
}
.icon-image {
  text-align: center;
  margin: 30px 0;
}
.icon-text {
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  margin: 20px auto;
  width: 100%;
  span {
    margin-right: 5px;
  }
}
@media (min-width: 768px) {
  .col-md-5th {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

.nav-pills .nav-link.gallery-tab__link {
  background-color: $primary;
  padding-top: 15px;
  padding-bottom: 15px;
  text-transform: uppercase;
  color: #fff;
  &.active {
    background-color: $secondary;
    color: $gray-800;
  }
}

.cont-bg {
  background: url("/image/catalog/banner-fon.jpg") no-repeat;
  background-size: cover;
  padding: 20px 10px 40px !important;
  text-align: center;
  border-radius: 6px;
  @include media-breakpoint-up(md) {
    padding: 20px 20px 40px !important;
  }
}
[id^="information-"] .s-page__description {
  font-weight: 500;
}
.s-page__description img {
  max-width: 100%;
  height: auto;
}
.section-about-company {
  line-height: 1.7;
}
.my-items {
  display: flex;
  flex-flow: row wrap;
  align-content: space-between;
  justify-content: space-between;
  border: 1px solid #ebebeb;
  background: #f7f7f7 none repeat scroll 0 0;
}
.my-item {
  width: 48%;
  margin: 10px;
  color: #000;
}
.my-block-content {
  img {
    width: 150px;
    height: 100px;
    object-fit: cover;
    margin-left: 10px;
    float: left;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  a {
    font-size: 15px;
    color: #333;
    text-decoration: none;
  }
}
